/* eslint-disable import/prefer-default-export */
import { encodeJSONtoString } from '../../../helpers/backToEncoding';
import { getSearchParams } from '../../../hooks/useBreadcrumbs';
import {
  categoryMapping,
  dateFields,
  documentCardHeadings,
  documentsSourceMapper,
  IGNORE_REG_360_BTN,
  LABEL_COLOR,
  METADATA_KEYS,
  SHOW_REG_360_BTN,
  sourceFlagMapping,
  sourceFlagMappingTooltip,
  LABEL_TOOLTIP_TITLE,
  designationTypeMap
} from '../constants';
import { Tag } from '../types/documentResultsUtils.types';

/**
 * Retrieves and constructs a list of metadata tags with their corresponding values.
 *
 * @param source The source key to look up in the METADATA_KEYS object.
 * @param data A record object where key is the tag id and value is the tag value.
 * @returns An array of Tag objects with each tag containing its corresponding value from the data parameter.
 *
 * Example:
 * getMetadataTagContents('us', { 'approval_date': '2023-01-01' })
 * Returns [{ id: 'approval_date', label: 'Approval Date', value: '2023-01-01' }, ...]
 */
export const getMetadataTagContents = (source: string, data: Record<string, any>): Tag[] => {
  const metadataKeys = METADATA_KEYS[source];
  // Early return for missing data or metadataKeys
  if (!metadataKeys || !data) return [];

  return metadataKeys
    .map(tag => {
      if (data[tag.id]) {
        // Only add or modify the value property
        return { ...tag, value: data[tag.id] };
      }
      return tag;
    })
    .filter(tag => tag.value !== undefined); // Filter out tags without a value
};

/**
 * Finds the document source name corresponding to a given data source.
 * This function searches through the documentsSourceMapper object to find a match for the provided dataSource.
 *
 * @param dataSource The data source string to search for in the documentsSourceMapper.
 * @returns The key (source name) from documentsSourceMapper where the dataSource is found, or null if not found.
 *
 * Example:
 * getDocumentSourceName('CDER-SBA')
 * Returns 'us' if 'CDER-SBA' is found under the 'us' key in documentsSourceMapper.
 */
export const getDocumentSourceName = (dataSource: string) => {
  return (
    // eslint-disable-next-line no-unused-vars
    Object.entries(documentsSourceMapper).find(([_, values]) => values.includes(dataSource))?.[0] ??
    null
  );
};

/**
 * Finds the document source name corresponding to a given data source.
 * This function searches through the documentsSourceMapper object to find a match for the provided dataSource.
 *
 * @param dataSource The data source string to search for in the documentsSourceMapper.
 * @returns The key (source name) from documentsSourceMapper where the dataSource is found, or null if not found.
 *
 * Example:
 * getDocumentSourceName('CDER-SBA')
 * Returns 'us' if 'CDER-SBA' is found under the 'us' key in documentsSourceMapper.
 */
export const getDocumentFlag = (dataSource: string) => {
  const sourceName = getDocumentSourceName(dataSource);
  if (sourceName) return sourceFlagMapping[sourceName];
  return null;
};

const startsWithNumber = (input: any) => {
  const firstChar = input.charAt(0);
  return !Number.isNaN(parseInt(firstChar, 10));
};

/**
 * Gets the document title based on the provided data source and document details.
 *
 * @param dataSource The data source string to determine the document title format.
 * @param documentResult The result object containing document details.
 * @param documentCardDetails The result object containing document details.
 * @returns The formatted document title.
 *
 * Example:
 * getDocumentTitle('PIP', { active_ingredient: 'exampleIngredient', trade_name: 'exampleTrade' }, { category: 'exampleCategory' });
 * Returns 'Titile: Exampleingredient (EXAMPLETRADE)' for PIP data source.
 * and alos updated the documentCardDetails.documentTitle
 */
export const getDocumentTitle = (
  dataSource: string,
  documentResult: { [key: string]: any },
  documentCardDetails: { [key: string]: any }
): string => {
  // eslint-disable-next-line no-underscore-dangle
  let title: string = documentResult.category || documentResult._category;

  if (
    [
      'Guidances',
      'PSG-Guidance',
      'FDA Written Requests',
      'CA Guidances',
      'FDA Letters',
      'SOPP',
      'MAPP'
    ].includes(dataSource)
  ) {
    title = `Title: ${documentResult.title}`;
    // eslint-disable-next-line no-param-reassign
    documentCardDetails.documentTitle = documentResult.title;
  } else if (dataSource === 'EMA Guidances') {
    title = `Title: ${documentResult.doc_title}`;
    // eslint-disable-next-line no-param-reassign
    documentCardDetails.documentTitle = documentResult.doc_title;
  } else if (dataSource === 'AdComm-CDER') {
    const formattedActiveIngredient: string =
      // eslint-disable-next-line no-unsafe-optional-chaining
      documentResult.active_ingredient?.charAt(0).toUpperCase() +
        // eslint-disable-next-line no-unsafe-optional-chaining
        documentResult.active_ingredient?.slice(1).toLowerCase() || '';
    // eslint-disable-next-line no-underscore-dangle
    title = `Title: ${formattedActiveIngredient}`;
    // eslint-disable-next-line no-param-reassign
    documentCardDetails.documentTitle = `${formattedActiveIngredient}`;
  } else if (dataSource === 'CHMP' && documentResult['chmp-meeting-id']) {
    title = `Title: ${documentResult['chmp-meeting-id']}`;
    // eslint-disable-next-line no-param-reassign
    documentCardDetails.documentTitle = `${documentResult['chmp-meeting-id']}`;
  }
  return title;
};

const reMapDesignationType = (designationType: string | undefined) => {
  return designationType && !Array.isArray(designationType) && designationTypeMap[designationType]
    ? designationTypeMap[designationType]
    : designationType;
};

/**
 * Prepares and returns a document card object with details extracted from a document result.
 * This function constructs a detailed object containing various properties extracted and computed from the document result.
 *
 * @param documentResult An object containing various properties of a document.
 * @returns An object containing details for a document card, including text, id, confidence score, and more.
 *
 * Example:
 * prepareDocumentCard({ data_source: 'CDER-SBA', text: 'Sample Text', ... })
 * Returns an object with structured data suitable for a document card display.
 */
export const prepareDocumentCard = (documentResult: any) => {
  const {
    location: { pathname: locationPathName, search: locationSearch }
  } = window;
  const documentCardDetails: any = {};
  documentCardDetails.paragraphText = documentResult.text;
  documentCardDetails.s3Link = documentResult.document_url;

  const dataSource = documentResult.data_source || documentResult['data-source'];
  let sourceName = getDocumentSourceName(dataSource);

  documentCardDetails.resultId = documentResult.result_id;
  documentCardDetails.queryId = documentResult.query_id;
  documentCardDetails.dataSource = documentResult.data_source ?? documentResult?.['data-source'];
  documentCardDetails.scoreConfidence = documentResult.score_confidence;
  // eslint-disable-next-line no-underscore-dangle
  documentCardDetails.category = documentResult.category || documentResult._category;
  documentCardDetails.metadataList = getMetadataTagContents(dataSource, documentResult);
  documentCardDetails.pdfUrl = documentResult.document_url;
  documentCardDetails.pdfUrlOriginal = documentResult?.document_url_original_language;
  documentCardDetails.isSuggestedAnswer = documentResult.type === 'answer';
  documentCardDetails['original-file-url'] =
    documentResult.original_file_url || documentResult['original-file-url'];
  documentCardDetails.sourceName = sourceName;

  let designationType = reMapDesignationType(documentResult?.application_type);
  // check for application type for usfda results
  if (designationType) {
    designationType =
      Array.isArray(designationType) && designationType.length > 0
        ? designationType[0]
        : designationType;
    // @ts-ignore -- ingoring because we are already checking the designationType validations in if condition
    const color = LABEL_COLOR[designationType.toLowerCase()];
    // @ts-ignore -- ingoring because we are already checking the designationType validations in if condition
    const tooltipTitle = LABEL_TOOLTIP_TITLE[designationType.toLowerCase()];

    if (color && designationType)
      documentCardDetails.applicationType = {
        label: designationType,
        tooltipTitle,
        color
      };
  }

  // check for reg360 option
  const applicationNumber =
    documentResult.application_number?.replace('EMEA-H-C-', '')?.replace('EMEA-H-W-', '') ||
    documentResult.product_number ||
    documentResult['product-number'] ||
    documentResult.nct_id ||
    documentResult.yj_code ||
    documentResult['vin-number'] ||
    documentResult.vin;

  const is360DataSourceAllowed =
    !IGNORE_REG_360_BTN.includes(dataSource?.toLowerCase() ?? '') &&
    (designationType ?? '') !== 'ANDA';

  if (
    applicationNumber &&
    SHOW_REG_360_BTN?.includes(sourceName?.toLowerCase() ?? '') &&
    is360DataSourceAllowed
  ) {
    if (
      sourceName?.toLowerCase() === 'hpra' &&
      (applicationNumber?.[0]?.startsWith('EMEA') || startsWithNumber(applicationNumber?.[0]))
    ) {
      sourceName = 'eu';
    }

    if (dataSource?.toLowerCase() === 'hma') {
      sourceName = 'hma';
    }
    const backToObject = {
      module,
      path: locationPathName,
      search: getSearchParams(locationSearch || ''),
      name: 'Search Results'
    };
    const backToString = encodeJSONtoString(backToObject);

    documentCardDetails.reg360 = `/regulatory360/core/${encodeURIComponent(
      applicationNumber
    )}/${sourceName}?category=indications_and_usage${
      sourceName === 'us' ? `&type=${documentResult?.application_type}` : ''
    }&backTo=${backToString}`;
  } else if (dataSource?.toLowerCase().includes('adcomm')) {
    // If the datasource is adcomm, navigate to that particular meeting when user clicks on the title.
    documentCardDetails.meetingLink = `/adcomm?committee=ALL&meetingId=${documentResult?.meeting_id}`;
  }

  const headings = documentCardHeadings[dataSource];

  if (headings) {
    headings.forEach(heading => {
      const documentResultValue = documentResult[heading.id];
      if (documentResultValue) {
        documentCardDetails[heading.placementType] = documentResultValue;
      }
    });
  }

  const title = getDocumentTitle(dataSource, documentResult, documentCardDetails);

  return {
    id: documentResult.result_id,
    item: documentResult,
    ...documentCardDetails,
    title,
    url: documentCardDetails.pdfUrl
  };
};

/**
 * Formats the document results by adding a generic property name for certain fields
 * @param {any} documentResults - The original document results object.
 * @returns A new object with formatted document results.
 */
export const formatDocumentResults = (documentResults: any) => {
  const updatedResults = documentResults?.results?.map((originalObj: any) => {
    // Create a new object to avoid direct modification of function parameter
    const obj = { ...originalObj };

    const dataSource = obj.data_source || obj['data-source'];

    // Find the first existing date field in the object
    const formattedDateField = dateFields.find(field =>
      Object.prototype.hasOwnProperty.call(obj, field)
    );

    // add document title in the object
    const headings = documentCardHeadings[dataSource];
    if (headings) {
      headings.forEach(heading => {
        const documentResultValue = obj[heading.id];
        if (documentResultValue) {
          obj[heading.placementType] = documentResultValue;
        }
      });
    }

    // add document category in the object
    obj.documentCategory = obj[categoryMapping[dataSource]];

    // If a date field is found, add 'documentDate'; otherwise, return the original object
    return formattedDateField ? { ...obj, documentDate: obj[formattedDateField] } : obj;
  });

  return { ...documentResults, results: updatedResults };
};

export const getDocumentFlagTooltipTitle = (dataSource: string) => {
  const sourceName = getDocumentSourceName(dataSource);
  if (sourceName) return sourceFlagMappingTooltip[sourceName];
  return null;
};

// Function to handle scrolling to aria result card when selected or when verify my answer is triggered from generative ai answer.
export const scrollToChild = (index: any) => {
  if (index === 0) {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  } else {
    let divPosition = document.getElementById(`child-${index}`)?.offsetTop ?? 0;
    window.scrollTo({
      top: divPosition - 20,
      behavior: 'smooth'
    });
  }
};

const findResultIndex = (resultId: string | undefined, filteredResults: any[]): number => {
  if (resultId !== undefined) {
    const index = filteredResults.findIndex((element: any) => element.result_id === resultId);
    return index !== -1 ? index : 0;
  }
  return 0;
};

export const scrollToResult = (resultId: string | undefined, filteredResults: any[]) => {
  const finalIndex = findResultIndex(resultId, filteredResults);
  setTimeout(() => {
    scrollToChild(finalIndex);
  }, 10);
};
