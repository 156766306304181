export const featureButton: any = {
  color: 'primary.main',
  textTransform: 'none',
  '&:not(:disabled)': {
    animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 4',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0.6)'
      },
      '70%': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 0 10px rgba(51, 177, 135, 0)'
      },
      '100%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0)'
      }
    }
  }
};
export const FAB: any = {
  position: 'fixed',
  bottom: 20,
  right: 20,
  backgroundColor: 'primary.main',
  '&:hover': {
    backgroundColor: 'primary.dark'
  },
  zIndex: 9999999,
  color: 'white.main',
  width: 'auto !important',
  transition: 'width 3s ease-in',
  textTransform: 'none',
  '&:not(:disabled)': {
    animation: 'pulse 1s cubic-bezier(0.4, 0, 0.6, 1) 4',
    '@keyframes pulse': {
      '0%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0.6)'
      },
      '70%': {
        transform: 'scale(1.05)',
        boxShadow: '0 0 0 10px rgba(51, 177, 135, 0)'
      },
      '100%': {
        transform: 'scale(1)',
        boxShadow: '0 0 0 0 rgba(51, 177, 135, 0)'
      }
    }
  }
};

export const chatWindow: any = {
  '& .MuiDrawer-paperAnchorRight': {
    top: 0
  },
  '& >.MuiDrawer-paper': {
    width: 650,
    height: `calc(100vh - 0px)`,
    maxHeight: `calc(100vh - 0px)`,
    overflow: 'hidden',
    boxShadow: '-0.1em 0.1em 5em #33b1874a'
  },
  '& >.MuiBackdrop-root': {
    backgroundColor: 'rgba(0,0,0,0.25)'
  }
};

export const pdfViewer: any = {
  width: 700,
  borderRadius: 5,
  height: '90vh',
  maxHeight: '90vh',
  mb: 10,
  background: 'white',
  justifyContent: 'center',
  alignItems: 'center',
  '& > div#pdfBox': { height: '94%', width: '96%' }
};

export const chatInitialize: any = {
  color: '#fff',
  position: 'absolute',
  zIndex: 9999999
};

export const closeButton: any = {
  border: '2px solid',
  width: '24px',
  height: '24px',
  color: 'white.main',
  borderColor: 'white.main',
  padding: '3px !important',
  ml: 1,
  '& > svg': {
    fontSize: 20
  },
  '&:hover': {
    color: 'primary.main',
    borderColor: 'primary.main',
    backgroundColor: 'white.main'
  }
};

export const chatContainer: any = {
  maxHeight: '100%',
  height: '100%',
  flexGrow: 1,
  overflowY: 'auto',
  position: 'relative',
  '&::-webkit-scrollbar': {
    width: '7px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'grey.300',
    borderRadius: '5px'
  }
};
export const chatBubble: any = {
  pl: 2,
  pr: 2,
  m: 0,
  flex: 1,
  borderRadius: 2,
  width: 'max-content',
  maxWidth: '95%',
  textAlign: 'left',
  '& p': {
    mt: 1,
    mb: 1
  },
  '& table': {
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      border: '1px solid',
      borderColor: 'grey.300',
      padding: 1,
      textAlign: 'center',
      backgroundColor: 'grey.100'
    },
    '& td': {
      border: '1px solid',
      borderColor: 'grey.300',
      padding: 1,
      textAlign: 'center'
    }
  }
};
export const chatBubbleUser: any = {
  pl: 2,
  pr: 2,
  mt: 0,
  flex: 1,
  borderRadius: 2,
  ml: '25px',
  width: 'max-content',
  maxWidth: '95%',
  textAlign: 'left',
  overflowX: 'hidden'
};
export const chatBubbleBot: any = {
  mt: 0,
  flex: 1,
  borderRadius: 2,
  width: 'max-content',
  maxWidth: '95%',
  textAlign: 'left',
  '& p': {
    mt: 1,
    mb: 1
  },
  '& table': {
    borderCollapse: 'collapse',
    width: '100%',
    '& th': {
      border: '1px solid',
      borderColor: 'grey.300',
      padding: 1,
      textAlign: 'center',
      backgroundColor: 'grey.100'
    },
    '& td': {
      border: '1px solid',
      borderColor: 'grey.300',
      padding: 1,
      textAlign: 'center'
    }
  },
  borderTopLeftRadius: 0,
  borderTopRightRadius: 8,
  overflowX: 'auto'
};
export const verifyButton: any = {
  width: 'max-content',
  textTransform: 'none',
  textAlign: 'left'
};

export const sectionChip: any = {
  backgroundColor: 'rgba(51,177,135,0.2)'
};
export const seeMoreButton: any = {
  height: 40,
  overflow: 'hidden',
  top: -25,
  left: 0,
  position: 'absolute',
  '& > button': {
    textTransform: 'none',
    backgroundColor: 'rgba(51,177,135,0.1)',
    backdropFilter: 'blur(10px)',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5
  }
};

export const chatInputSend: any = {
  borderRadius: 2,
  p: '8px 12px',
  minWidth: 0
};

export const sectionSelection: any = {
  p: 0,
  textTransform: 'none',
  color: 'black !important',
  minWidth: 'auto',
  '& .MuiButton-endIcon': {
    backgroundColor: 'rgba(51,177,135,0.1)',
    borderRadius: 50
  }
};

export const modeSwitchRoot: any = {
  '& .MuiFormControlLabel-label': {
    ml: 1,
    fontWeight: 'bold'
  },
  '& .MuiSwitch-root': {
    width: '60px !important'
  }
};

export const errorMessageDiv: any = {
  right: `610px !important`,
  top: `10px !important`,
  width: `200px !important`,
  borderRadius: '10px !important'
};

export const defaultAvatar: any = {
  '& > .MuiAvatar-root': {
    borderRadius: 2,
    backgroundColor: 'primary.avatar'
  }
};

export const verifyAnswerSnackbar: any = {
  right: `655px !important`,
  top: `50px !important`
};

export const cancelRequestButton: any = {
  width: '100%',
  position: 'absolute',
  bottom: 10,
  left: 0,
  right: 0,
  zIndex: 1
};
export const changePageRequestButton: any = {
  width: '100%',
  position: 'absolute',
  bottom: 80,
  left: 0,
  right: 0,
  zIndex: 1
};

export const formContainer: any = {
  width: '100%',
  '& > form': {
    flex: 1,
    transaction: 'width 0.5s ease-out'
  },
  display: 'flex',
  padding: '0px 16px',
  pb: '20px',
  alignitems: 'center',
  justifyContent: 'space-between'
};

export const inputBox: any = {
  borderRadius: '8px',
  borderColor: 'grey.400',
  fontSize: 13,
  display: 'flex',
  padding: '8px 8px 8px 16px',
  fontFamily: 'Mulish',
  fontWeight: 400,
  height: '40px',
  '& .Mui-focused': {
    borderColor: 'grey.600'
  },
  '& .MuiOutlinedInput-input': {
    borderColor: 'grey.600'
  }
};

export const helpButton: any = { ml: 'auto', mt: 0.5, mr: 2 };
export const applicationSelection: any = {
  width: '100%',
  maxWidth: 200,
  ml: 1,
  mt: 0.5, // '& fieldset': {
  //   border: 'none'
  // },
  '& div > div ': {
    p: '0 !important'
  },
  '& .MuiAutocomplete-inputRoot': {
    fontWeight: 'bold'
  },
  '& .MuiAutocomplete-popper': {
    minWidth: '700px !important',
    width: '700px !important'
  },
  fontWeight: 'bold'
};
export const deleteIcon: any = {
  position: 'absolute',
  top: 0,
  right: 0,
  zIndex: 1,
  '& .MuiSvgIcon-root': {
    fontSize: 20
  }
};

export const reportDialog: any = {
  '& .MuiDialog-paper': {
    height: '98%',
    maxWidth: 'none',
    maxHeight: 'none',
    p: 0,
    my: 'auto',
    borderRadius: 2,
    transition: 'all 3s ease-in-out'
  }
};

export const reportLoader: any = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  zIndex: 100,
  backgroundColor: 'rgba(0,0,0,0.5)'
};

export const reportLoaderContainer: any = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 100,
  backgroundColor: 'white',
  borderRadius: 2,
  p: 2,
  textAlign: 'center'
};

export const reportContainer: any = {
  maxHeight: 'calc(100vh - 200px)',
  overflow: 'hidden'
};

export const reportQuestionContainer: any = {
  width: '40%',
  mr: 2,
  overflowY: 'auto',
  overflowX: 'hidden'
};

export const reportQuestionList: any = {
  py: 0,
  '& .MuiListItem-root': {
    mb: 2
  }
};
export const reportAnswerContainer: any = {
  width: '21cm',
  minHeight: '100%',
  overflow: 'auto',
  border: '1px solid #c9bebe',
  p: 2
};

export const reportAnswer: any = {
  width: '95%',
  borderRadius: 2,
  '& .MuiTypography-subtitle1': {
    maxWidth: '100%'
  }
};

export const reportAnswerSeparator: any = {
  borderBottom: '1px solid #c9bebe',
  width: '100%',
  my: 2,
  mx: 'auto'
};
export const reportCancelButton: any = { textTransform: 'capitalize', mr: 'auto', ml: 3 };
export const disableSelectUnderline: any = {
  '&:before': {
    border: 'none !important'
  },
  '&:after': {
    border: 'none !important'
  }
};
export const reportDownloadButton: any = {
  ml: 1,
  minWidth: 100,
  borderRadius: 2,
  color: 'white.main',
  textTransform: 'none'
};
export const reportQuestionDeleteContainer: any = {
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  backgroundColor: `rgba(255, 255, 255, .15)`,
  backdropFilter: `blur(5px)`,
  zIndex: 2
};

export const reportQuestionDeleteButtons: any = {
  mr: 2,
  color: `black.light`,
  textTransform: 'none',
  backgroundColor: `rgba(51, 177, 135, 0.5)`,
  backdropFilter: `blur(5px)`
};

export const userAvatar: any = {
  borderRadius: '14.715px',
  width: '24px',
  height: '24px',
  backgroundColor: 'secondary.600',
  padding: '3.3px 5.7px 2.7px 6.3px'
};

export const userText: any = {
  color: 'grey.900',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '400',
  ml: 1
};

export const subText: any = {
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '500'
};

export const sectionStyle: any = {
  display: 'flex',
  padding: '20px',
  alignItems: 'center',
  gap: '12px',
  alignSelf: 'stretch',
  borderBottom: '1px solid',
  borderColor: 'gray.200',
  backgroundColor: 'grey.50',
  justifyContent: 'fit-content'
};

export const botAnswer: any = {
  width: '100%',
  borderRadius: '12px',
  border: '1px solid',
  borderColor: 'primary.100',
  backgroundColor: 'grey.50',
  p: '16px',
  marginBottom: '20px'
};

export const applicationContainer = {
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 0,
  right: 0,
  width: 320,
  height: '100vh',
  marginRight: '650px',
  borderRadius: '8px 0px 0px 8px',
  borderRight: '1px solid',
  borderColor: 'primary.avatar',
  backgroundColor: 'white.main'
};

export const applicationHeader = {
  display: 'flex',
  height: '6%',
  padding: '12px 20px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  alignSelf: 'stretch',
  backgroundColor: 'primary.300',
  borderRadius: '8px 0px 0px 0px'
};

export const applicationScroll = {
  overflowY: 'auto',
  '&::-webkit-scrollbar': {
    width: '7px'
  },
  '&::-webkit-scrollbar-thumb': {
    backgroundColor: 'grey.300',
    borderRadius: '5px'
  },
  borderRadius: '0px 0px 0px 8px'
};

export const accondionSummary = {
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  '& .MuiAccordionSummary-content': {
    display: 'flex',
    alignItems: 'center',
    height: '20px',
    width: '100%'
  },
  '& .MuiPaper-root': {
    width: '100%'
  }
};

export const suggestionList = {
  border: '1px solid',
  borderColor: 'primary.main',
  borderRadius: 2,
  mb: '12px',
  backgroundColor: 'white.main',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexDirection: 'row',
  p: '12px',
  width: '100%'
};

export const userAvatarText: any = {
  color: 'grey.900',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '700',
  ml: 1
};

export const messageDivider: any = {
  borderColor: 'grey.400',
  width: '100%',
  height: '1px',
  position: 'absolute',
  borderStyle: 'dashed'
};

export const chatBubbleStyles: any = {
  bubbleIcon: { height: '60px', width: '60px', color: '#06705F' },
  chatBubbleContainer: {
    width: '100%',
    p: '0px 20px 10px 20px'
  },
  sectionContainer: {
    '& .MuiStack-root': {
      ml: '20px'
    }
  },
  sectionText: {
    color: 'grey.800',
    fontFamily: 'Mulish',
    fontSize: '13px',
    fontWeight: '400',
    cursor: 'pointer'
  }
};

export const suggestionButtonStyle: any = {
  width: '20px',
  height: '20px',
  color: 'white',
  '&:hover': {
    color: 'primary.600'
  }
};

export const markdownStyle: any = {
  suggestionText: {
    color: 'black.main',
    fontWeight: 600,
    lineHeight: '21px',
    fontSize: '13px'
  },
  suggestionIcon: { width: '16px', height: '16px', color: 'orange.main' },
  chatHeaderText: {
    color: 'primary.900',
    fontWeight: 700,
    lineHeight: '21px'
  },
  container: {
    cursor: 'pointer',
    display: 'inline-flex'
  },
  IconStyle: {
    height: '14px',
    width: '14px',
    color: 'primary.700'
  },
  Text: {
    color: 'primary.700',
    cursor: 'pointer',
    fontWeight: 600,
    lineHeight: '14px'
  }
};

export const brandNameText: any = {
  color: 'primary.700',
  textAlign: 'center',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '700',
  textTransform: 'capitalize',
  textDecoration: 'underline',
  cursor: 'pointer'
};

export const badgeStyle: any = {
  display: 'inline-flex',
  padding: '2px 4px',
  alignItems: 'center',
  '& .MuiBadge-badge': {
    height: '16px',
    minWidth: '16px',
    fontSize: '10px',
    fontWeight: 700,
    ml: '4px',
    mr: '4px',
    color: 'white.main',
    backgroundColor: '#D43B2B'
  }
};

export const reportText: any = {
  color: 'grey.700',
  textAlign: 'center',
  fontFamily: 'Mulish',
  fontSize: '13px',
  fontWeight: '500',
  textTransform: 'capitalize',
  cursor: 'pointer'
};

export const accordionTitle: any = {
  color: 'grey.800',
  fontFamily: 'Mulish',
  fontSize: '14px',
  fontWeight: '500',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden'
};

export const chipMetadata: any = {
  ml: 1,
  fontSize: 10,
  height: 13,
  borderRadius: 1,
  fontWeight: 800,
  color: 'white.main',
  '& span': {
    px: 0.5
  }
};

export const chatRiaWarningStyle: any = {
  warningContainer: {
    display: 'flex',
    py: 1,
    px: 2.5,
    flexDirection: 'row',
    alignItems: 'center',
    gap: 2,
    border: '1px solid'
  },
  boxContainer: {
    display: 'flex',
    width: 24,
    height: 24,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%'
  },
  warningText: {
    color: 'red.lightVariant',
    fontSize: 13,
    fontWeight: 600
  },
  warningSubText: {
    color: 'red.lightVariant',
    fontSize: 13,
    fontWeight: 400,
    fontStyle: 'italic'
  },
  warningIcon: {
    width: 14,
    height: 14
  }
};
