import { CHAT_RIA_ALLOWED_SOURCES } from '../../../components/ChatRIA/const';

const showChatRia = (data: any, source: string) => {
  if (source === 'us' && data?.application_type === 'EUA') {
    return false;
  }
  if (source === 'au' && data?.s3_label_url === null) {
    return false;
  }
  return CHAT_RIA_ALLOWED_SOURCES.includes(source);
};

export default showChatRia;
