import { createSvgIcon } from '@mui/material';

const ChatRiaWarningIcon = createSvgIcon(
  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
    <g clipPath='url(#clip0_6197_4739)'>
      <path
        d='M6.00245 2.75166L1.06162 11C0.959749 11.1764 0.905848 11.3764 0.905278 11.5801C0.904708 11.7838 0.957487 11.9842 1.05837 12.1611C1.15925 12.3381 1.30471 12.4856 1.48028 12.5889C1.65585 12.6922 1.85542 12.7478 2.05912 12.75H11.9408C12.1445 12.7478 12.344 12.6922 12.5196 12.5889C12.6952 12.4856 12.8407 12.3381 12.9415 12.1611C13.0424 11.9842 13.0952 11.7838 13.0946 11.5801C13.0941 11.3764 13.0402 11.1764 12.9383 11L7.99745 2.75166C7.89346 2.58023 7.74704 2.43848 7.57232 2.34011C7.39759 2.24174 7.20046 2.19006 6.99995 2.19006C6.79944 2.19006 6.60231 2.24174 6.42759 2.34011C6.25286 2.43848 6.10644 2.58023 6.00245 2.75166V2.75166Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M7 10.4167H7.00583' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
      <path d='M7 5.75V8.08333' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_6197_4739'>
        <rect width='14' height='14' fill='white' transform='translate(0 0.5)' />
      </clipPath>
    </defs>
  </svg>,
  'ChatRiaWarningIcon'
);
export default ChatRiaWarningIcon;
