import { CHAT_RIA_ALLOWED_SOURCES } from '../../../components/ChatRIA/const';
import showChatRia from './showChatRia';

const triggerGlobalChatRIA = async (
  applications: any,
  globalActions: any,
  globalDispatch: any,
  viewType: any
) => {
  // handling the start index for unsupported sources
  let selectedApplicationIndex = applications.findIndex((app: any) =>
    CHAT_RIA_ALLOWED_SOURCES.includes(app?.source || '')
  );
  if (selectedApplicationIndex === -1) selectedApplicationIndex = 0;
  // const updatedApplicationList = applications.filter(
  //   (app: any) => app.source === applications[selectedApplicationIndex].source
  // );
  globalDispatch({
    type: globalActions.SET_CHATRIA_TRIGGERED_FROM,
    value: viewType
  });
  globalDispatch({
    type: globalActions.SET_APPLICATION_SOURCE,
    value: applications[selectedApplicationIndex].source
  });
  // only show applications that have chat ria enabled
  const filteredList = applications.filter((item: any) => showChatRia(item, item.source) === true);
  globalDispatch({ type: globalActions.SET_APPLICATION_LIST, value: filteredList });
  globalDispatch({ type: globalActions.SET_CHATRIA_OPEN, value: true });
};

export default triggerGlobalChatRIA;

export const triggerChatRiaForReg360 = async (
  applicationNumber: string,
  tradeName: string,
  globalActions: any,
  source: any,
  globalDispatch: any
) => {
  globalDispatch({
    type: globalActions.SET_CHATRIA_TRIGGERED_FROM,
    value: 'regulatory360'
  });
  globalDispatch({
    type: globalActions.SET_APPLICATION_NUMBER,
    value: applicationNumber
  });
  globalDispatch({ type: globalActions.SET_APPLICATION_NAME, value: tradeName });
  globalDispatch({
    type: globalActions.SET_APPLICATION_SOURCE,
    value: source
  });
  globalDispatch({ type: globalActions.SET_CHATRIA_OPEN, value: true });
};
