const getDocumentMetadata = (
  source: string,
  applicationDetails: any,
  dataItem: any,
  category: string
) => {
  if (['us', 'eu', 'us-devices'].includes(source)) {
    return {
      active_ingredient: `${applicationDetails?.generic_name || ''} (${
        applicationDetails?.product_name || ''
      })`,
      approval_date:
        source === 'us-devices' ? dataItem.decision_date ?? '' : dataItem.approval_date ?? '',
      application_number: applicationDetails?.application_number
        ? applicationDetails?.application_number ?? ''
        : applicationDetails?.identifier,
      submission_id:
        source === 'us'
          ? `${dataItem?.submission_type}-${dataItem?.submission_num}`
          : dataItem?.submission_num?.toString() ?? dataItem?.submission,
      category
    };
  }
  if (source === 'ca') {
    return {
      'active-ingredient': `${applicationDetails?.generic_name || ''} (${
        applicationDetails?.product_name || ''
      })`,
      'submission-approval-date': dataItem.approval_date ?? '',
      'vin-number': applicationDetails?.application_number ?? '',
      'brand-name': applicationDetails?.trade_name ?? '',
      _category: category
    };
  }
  if (source === 'jp') {
    return {
      'active-ingredient': `${applicationDetails?.generic_name || ''} (${
        applicationDetails?.product_name || ''
      })`,
      'approval-date': dataItem.approval_date ?? '',
      yj_code: applicationDetails?.application_number ?? '',
      'trade-name': applicationDetails?.trade_name ?? '',
      _category: category
    };
  }
  if (source === 'hma') {
    return {
      active_ingredient: applicationDetails?.product_name ?? '',
      approval_date: dataItem.approval_date ?? '',
      product_name: applicationDetails?.application_number ?? '',
      sponsor: applicationDetails?.trade_name ?? '',
      category
    };
  }
  if (source === 'hpra') {
    return {
      'active-ingredient': applicationDetails?.product_name ?? '',
      'approval-date': dataItem.approval_date ?? '',
      'product-number': applicationDetails?.application_number ?? '',
      sponsor: applicationDetails?.trade_name ?? '',
      _category: category
    };
  }
  if (source === 'au') {
    return {
      active_ingredients: applicationDetails?.generic_name ?? '',
      start_date: dataItem.approval_date ?? '',
      vin: applicationDetails?.application_number ?? '',
      sponsor: applicationDetails?.product_name ?? ''
    };
  }

  return {};
};

export default getDocumentMetadata;
